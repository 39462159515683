import React from 'react';
import Layout from '../components/Layout';

const ContactFormulierVerstuurd = () => (
  <Layout title="Contactformulier verstuurd">
    <div className="container py-6 px-5 has-text-centered">
      <h1 className="title">Contactformulier is met success verstuurd</h1>
      <p>Een kopie van de gegevens is ook naar uw mailbox gestuurd.</p>
    </div>
  </Layout>
);

export default ContactFormulierVerstuurd;
